import React, { useEffect, useState, ReactNode } from "react"
import Tooltip from "@material-ui/core/Tooltip"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { withStyles } from "@material-ui/styles"
import { Theme, Zoom } from "@material-ui/core"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import * as styles from "./Tooltip.module.scss"
import { TooltipIcon } from "../../../images/svg/Tooltip"
import { QuestionIcon } from "../../../images/svg/QuestionIcon"
import { DefaultClose } from "./icons/DefaultClose"
import { SmallClose } from "./icons/SmallClose"

interface ITooltipQuestion {
  text: string | ReactNode
  hintClassName: any
  anotherTooltipIcon?: boolean
  smallCloseIcon?: boolean
  variantStyles?: "calcWithForm"
}

export const TooltipQuestion = ({
  text,
  hintClassName,
  anotherTooltipIcon,
  smallCloseIcon,
  variantStyles,
}: ITooltipQuestion) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      setOpen(false)
    }

    window.addEventListener("scroll", () => {
      handleScroll()
    })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleTooltipOpen = () => {
    setOpen(true)
    pushToDataLayer({ event: "buttonClick", name: "click_informer" })
  }
  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      color: "#000",
      position: "absolute",
      pointerEvents: "auto",
      cursor: "default",
      padding: "12px 16px",
      background: "#ffffff",
      border: "1px solid #cdcdcd",
      boxSizing: "border-box",
      boxShadow: " 0px 8px 14px rgba(122, 122, 122, 0.25)",
      borderRadius: 8,
      width: 250,
      transition: " 0.3s all",
      marginTop: 16,
      ...hintClassName.xs,

      [theme.breakpoints.up("sm")]: {
        ...hintClassName.sm,
      },

      [theme.breakpoints.up("md")]: {
        ...hintClassName.md,
      },

      [theme.breakpoints.up("lg")]: {
        ...hintClassName.lg,
      },
    },
  }))(Tooltip)
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <span className={styles.wrapper}>
        <HtmlTooltip
          PopperProps={{
            disablePortal: false,
          }}
          onClose={handleTooltipOpen}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <div className={variantStyles && styles[variantStyles]}>
              <div className={styles.contentTooltip}>
                <div className={styles.hintText}>{text}</div>
                <div className={styles.close} onClick={handleTooltipClose} aria-hidden="true">
                  {smallCloseIcon ? <SmallClose /> : <DefaultClose />}
                </div>
              </div>
            </div>
          }
          TransitionComponent={Zoom}
        >
          <div onClick={handleTooltipOpen} aria-hidden="true" className={styles.tooltipIcon}>
            {anotherTooltipIcon ? <QuestionIcon /> : <TooltipIcon />}
          </div>
        </HtmlTooltip>
      </span>
    </ClickAwayListener>
  )
}
