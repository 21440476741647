import React, { type ReactNode } from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"

import { TooltipQuestion } from "./Tooltip/Tooltip"
import { GradientBg } from "../GradientBg"

import { halvaSKeshbekomItems } from "./helpers"

import * as styles from "./moreBenefitsHalvaSKeshbekom.module.scss"

type MoreBenefitsRedesignProps = {
  title?: ReactNode
  subtitle?: ReactNode
  hasSubtitle?: boolean
  orderNum?: string
}

const itemsList = halvaSKeshbekomItems.map(
  ({ Img, alt, title: itemTitle, tooltipHint, hintClassName }, i) => (
    <GradientBg key={i} className={clsx(styles.item, styles[`item${i + 1}`])}>
      <div className={styles.itemText}>
        <p>
          <span>{itemTitle}</span>
          {tooltipHint && <TooltipQuestion text={tooltipHint} hintClassName={hintClassName} />}
        </p>
      </div>

      <Img alt={alt} className={styles.img} />
    </GradientBg>
  )
)

export const MoreBenefitsHalvaSKeshbekom = ({
  title = "Больше выгоды",
  subtitle = "С подпиской «Халва.Десятка»",
  hasSubtitle = true,
  orderNum,
}: MoreBenefitsRedesignProps) => (
  <section className={styles.section} id="moreBenefits" data-exclude={orderNum}>
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.textBlock}>
          <div className={styles.title}>{title}</div>
          {hasSubtitle && <div className={styles.desc}>{subtitle}</div>}
        </div>
        <div className={clsx(styles.wrap)}>{itemsList}</div>
      </div>
    </Container>
  </section>
)
