import React from "react"

import { FourPercent, TenPercent, TenPercentYandexGo, TwoPercent } from "./images"
import { BenefitsItem } from "./types"

const hintClassNames = {
  c1: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      left: 22,
      top: -40,
    },
    md: {
      top: -8,
      left: -16,
    },

    lg: {
      left: 24,
      top: -45,
    },
  },

  c2: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      left: 20,
      top: -30,
    },
    md: {
      left: 22,
      top: -43,
    },

    lg: {
      left: 24,
      top: -45,
    },
  },

  c3: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      top: -5,
      left: -50,
    },
    md: {
      top: -10,
      left: -75,
    },

    lg: {
      left: 24,
      top: -45,
    },
  },

  c4: {
    xs: {
      left: 20,
      top: -30,
    },
    sm: {
      left: 22,
      top: -40,
    },
    md: {
      left: -180,
      top: 0,
    },

    lg: {
      left: -180,
      top: 0,
    },
  },
}

export const halvaSKeshbekomItems: BenefitsItem[] = [
  {
    Img: TenPercent,
    alt: "Ten percent cashback",
    title: (
      <>
        <b>Кэшбэк&nbsp;10%</b> на&nbsp;все покупки
      </>
    ),
    tooltipHint: (
      <>
        При оплате стикер-PAY/покупки у магазинов-партнеров через интернет 10 % за покупки от 10 000
        с&nbsp;подпиской, 5&nbsp;%&nbsp;за&nbsp;покупки от 10 000 без подписки
      </>
    ),
    hintClassName: hintClassNames.c1,
  },
  {
    Img: FourPercent,
    alt: "Four percent cashback",
    title: (
      <>
        <b>Кэшбэк 4%</b> за&nbsp;каждую покупку у&nbsp;партнера
      </>
    ),
    tooltipHint: (
      <>
        4 % за каждую покупку картой Халва с подпиской, <br />2 % за каждую покупку картой Халва без
        подписки
      </>
    ),
    hintClassName: hintClassNames.c2,
  },
  {
    Img: TenPercentYandexGo,
    alt: "Ten percent cashback for YandexGo",
    title: (
      <>
        <b>Кэшбэк 10%</b> на такси «Яндекс Go»
      </>
    ),
    tooltipHint: (
      <>
        10 % за каждую поездку с&nbsp;подпиской, 5&nbsp;%&nbsp;за&nbsp;каждую поездку без подписки
      </>
    ),
    hintClassName: hintClassNames.c3,
  },
  {
    Img: TwoPercent,
    alt: "Two percent cashback",
    title: (
      <>
        <b>Кэшбэк 2%</b> за&nbsp;покупки не&nbsp;у&nbsp;партнеров и&nbsp;оплату услуг
        в&nbsp;приложении
      </>
    ),
    tooltipHint: (
      <>
        2% за покупки не&nbsp;у&nbsp;партнеров и оплату услуг ЖКХ в приложении с&nbsp;подпиской, 1%
        за покупки не у партнеров и оплату услуг ЖКХ в приложении без подписки
      </>
    ),
    hintClassName: hintClassNames.c4,
  },
]
