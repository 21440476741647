// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--1u9d+";
export var btnBlock = "banner-module--btnBlock--inWMM";
export var container = "banner-module--container--SZwRk";
export var description = "banner-module--description--+r9Q6";
export var hideMobBlock = "banner-module--hideMobBlock--b2ehG";
export var img = "banner-module--img--m322I";
export var mobBtn = "banner-module--mobBtn--5lxnG";
export var root = "banner-module--root--Q0ts0";
export var section = "banner-module--section--I0NJX";
export var text = "banner-module--text--DXRdK";
export var title = "banner-module--title--I9p+n";