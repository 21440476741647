// extracted by mini-css-extract-plugin
export var desc = "moreBenefitsHalvaSKeshbekom-module--desc--8CNIt";
export var img = "moreBenefitsHalvaSKeshbekom-module--img--JNnAh";
export var item = "moreBenefitsHalvaSKeshbekom-module--item--JL9Bv";
export var item1 = "moreBenefitsHalvaSKeshbekom-module--item1--exr-O";
export var item2 = "moreBenefitsHalvaSKeshbekom-module--item2--O64lz";
export var item3 = "moreBenefitsHalvaSKeshbekom-module--item3--0ZObr";
export var item4 = "moreBenefitsHalvaSKeshbekom-module--item4--fYSSD";
export var itemText = "moreBenefitsHalvaSKeshbekom-module--itemText--Aoq-e";
export var link = "moreBenefitsHalvaSKeshbekom-module--link--l+144";
export var section = "moreBenefitsHalvaSKeshbekom-module--section--VUgX1";
export var textBlock = "moreBenefitsHalvaSKeshbekom-module--textBlock--PhhRR";
export var title = "moreBenefitsHalvaSKeshbekom-module--title--Wjnik";
export var wrap = "moreBenefitsHalvaSKeshbekom-module--wrap--q40J6";
export var wrapper = "moreBenefitsHalvaSKeshbekom-module--wrapper--HL4X6";